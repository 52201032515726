@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap");

:root {
  --accent-button-bg: #02d2af;
  --accent-button-font: white;
  --footer-bg: rgba(2, 210, 175, 0.1);
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

input {
  padding: 12px;
  font-size: 16px;
  margin: 4px 0px 12px 0;
  width: 220px;
  border: 1px solid grey;
}

h1 {
  font-size: 64px;
  line-height: 120%;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: bold;
  line-height: 120%;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

p {
  line-height: 170%;
}

section {
  min-height: 90vh;
  display: grid;
  padding: 14px 28px;
}

button {
  padding: 16px;
  background-color: var(--accent-button-bg);
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  color: var(--accent-button-font);
  border: none;
}

header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px;
  background-color: var(--footer-bg);
  width: 100%;
  box-sizing: border-box;
}

label {
  font-size: 16px;
}

footer > a {
  padding: 0px 32px;
}

/* centers the whole app */
.root {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  justify-items: center;
  font-family: "Raleway";
}

/* has sections as children */
.container {
  max-width: 1200px;
  width: 100%;
}

.section-1 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-content: center;
}

.section-2 {
  grid-template-areas: "calendar-anim bullet-points";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-content: center;
}

.calndr-anim-container {
  grid-area: calendar-anim;
}

.bullet-points-container {
  grid-area: bullet-points;
}
.section-3 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  align-content: center;
}

.bullet-points-container > div {
  margin-top: 32px;
}

.error-text {
  color: red;
  font-size: 12px;
}
.mt-10 {
  margin-top: 10px;
}
.fb-btn {
  background: #3b5998;
}
/* bigger screens */
@media only screen and (min-width: 600px) {
  header > h3 {
    font-size: 40px;
  }

  .start-btn {
    margin-top: 32px;
    width: 70%;
  }

  .phone-visible {
    display: none;
  }

  .bullet-points-container > div {
    margin: 48px;
  }
}

/* smaller screensw */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 36px;
    font-weight: bold;
    line-height: 120%;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
  }

  p {
    line-height: 170%;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 28px;
    background-color: var(--footer-bg);
  }

  .section-1 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .section-2 {
    grid-template-areas: "bullet-points" "calendar-anim";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .section-3 {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .desktop-visible {
    display: none;
  }
}
